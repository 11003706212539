@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.hop-Spinner {
    --hop-Spinner-border-radius: var(--hop-shape-circle);
    --hop-Spinner-sm-wheel-size: 1rem;
    --hop-Spinner-md-wheel-size: 1.25rem;
    --hop-Spinner-lg-wheel-size: 1.5rem;

    /* Track */
    --hop-Spinner-track-width: 0.125rem;
    --hop-Spinner-track-opacity: 0.2;
    --hop-Spinner-track-border: var(--hop-Spinner-track-width) solid currentcolor;

    /* Fill */
    --hop-Spinner-fill-animation: spin .8s infinite linear;
    --hop-Spinner-fill-animation-reduced-motion-duration: 2s;
    --hop-Spinner-fill-border: var(--hop-Spinner-track-width) solid transparent;
    --hop-Spinner-fill-border-inline-start: var(--hop-Spinner-track-width) solid currentcolor;

    /* Label */
    --hop-Spinner-label-margin-inline-start: var(--hop-space-inline-sm);

    display: grid;
    grid-template-areas: "spinner label";
    grid-template-columns: min-content auto;
    align-items: center;

    box-sizing: border-box;
}

.hop-Spinner--sm {
    --width: var(--hop-Spinner-sm-wheel-size);
    --height: var(--hop-Spinner-sm-wheel-size);
}

.hop-Spinner--md {
    --width: var(--hop-Spinner-md-wheel-size);
    --height: var(--hop-Spinner-md-wheel-size);
}

.hop-Spinner--lg {
    --width: var(--hop-Spinner-lg-wheel-size);
    --height: var(--hop-Spinner-lg-wheel-size);
}

.hop-Spinner__track {
    grid-area: spinner;

    box-sizing: border-box;
    inline-size: var(--width, var(--hop-Spinner-md-wheel-size));
    block-size: var(--height, var(--hop-Spinner-md-wheel-size));

    opacity: var(--hop-Spinner-track-opacity);
    border: var(--hop-Spinner-track-border);
    border-radius: var(--hop-Spinner-border-radius);
}

.hop-Spinner__fill {
    grid-area: spinner;

    box-sizing: border-box;
    inline-size: var(--width, var(--hop-Spinner-md-wheel-size));
    block-size: var(--height, var(--hop-Spinner-md-wheel-size));

    border: var(--hop-Spinner-fill-border);
    border-inline-start: var(--hop-Spinner-fill-border-inline-start);
    border-radius: var(--hop-Spinner-border-radius);

    animation: var(--hop-Spinner-fill-animation);
}

@media (prefers-reduced-motion: reduce) {
    .hop-Spinner__fill {
        animation-duration: var(--hop-Spinner-fill-animation-reduced-motion-duration);
    }
}

.hop-Spinner__label {
    grid-area: label;
    margin-inline-start: var(--hop-Spinner-label-margin-inline-start);
}
