.hop-InputGroup {
    --hop-InputGroup-background: var(--hop-neutral-surface);
    --hop-InputGroup-border-color: var(--hop-neutral-border);
    --hop-InputGroup-border-width: 0.0625rem;
    --hop-InputGroup-gap: var(--hop-space-inline-sm);
    --hop-InputGroup-inline-size: fit-content;
    --hop-InputGroup-border-radius: var(--hop-shape-rounded-md);
    --hop-InputGroup-padding-inline: var(--hop-space-inset-md);
    --hop-InputGroup-padding-block: 0;
    --hop-InputGroup-border-color-hover: var(--hop-neutral-border-hover);
    --hop-InputGroup-border-color-focus: var(--hop-primary-border-focus);
    --hop-InputGroup-background-hover: var(--hop-neutral-surface-hover);
    --hop-InputGroup-border-color-invalid: var(--hop-danger-border-strong);
    --hop-InputGroup-background-disabled: var(--hop-neutral-surface-disabled);
    --hop-InputGroup-border-color-disabled: var(--hop-neutral-border-disabled);

    /* Internal Variables */
    --inline-size: var(--hop-InputGroup-inline-size);
    --border: var(--hop-InputGroup-border-width) solid var(--hop-InputGroup-border-color);
    --background: var(--hop-InputGroup-background);

    position: relative;

    display: inline-flex;
    flex-direction: row;
    gap: var(--hop-InputGroup-gap);
    align-items: center;

    box-sizing: border-box;
    inline-size: var(--inline-size);
    padding-block: var(--hop-InputGroup-padding-block);
    padding-inline: var(--hop-InputGroup-padding-inline);

    background: var(--background);
    border: var(--border);
    border-radius: var(--hop-InputGroup-border-radius)
}

.hop-InputGroup--fluid {
    --inline-size: 100%;
}

.hop-InputGroup[data-hovered] {
    --background: var(--hop-InputGroup-background-hover);
    --border: var(--hop-InputGroup-border-width) solid var(--hop-InputGroup-border-color-hover)
}

.hop-InputGroup[data-focus-within] {
    --border: var(--hop-InputGroup-border-width) solid var(--hop-InputGroup-border-color-focus)
}

.hop-InputGroup[data-invalid] {
    --border: var(--hop-InputGroup-border-width) solid var(--hop-InputGroup-border-color-invalid)
}

.hop-InputGroup[data-disabled] {
    --background: var(--hop-InputGroup-background-disabled);
    --border: var(--hop-InputGroup-border-width) solid var(--hop-InputGroup-border-color-disabled);
}
