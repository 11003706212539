.hop-Input {
    --hop-Input-placeholder-font-family: inherit;
    --hop-Input-placeholder-font-size: inherit;
    --hop-Input-placeholder-font-weight: inherit;
    --hop-Input-placeholder-line-height: inherit;
    --hop-Input-placeholder-color: var(--hop-neutral-text-weakest);
    --hop-Input-placeholder-color-disabled: var(--hop-neutral-text-disabled);
    --hop-Input-sm-block-size: 1.875rem; /** calc(2rem - 0.125rem) account for border */
    --hop-Input-md-block-size: 2.375rem; /** calc(2.5rem - 0.125rem) account for border */
    --hop-Input-color: inherit;
    --hop-Input-color-disabled: var(--hop-neutral-text-disabled);
    --hop-Input-background: transparent;
    --hop-Input-background-disabled: var(--hop-neutral-surface-disabled);
    --hop-Input-sm-font-family: var(--hop-body-sm-font-family);
    --hop-Input-sm-font-size: var(--hop-body-sm-font-size);
    --hop-Input-sm-font-weight: var(--hop-body-sm-font-weight);
    --hop-Input-sm-line-height: var(--hop-body-sm-line-height);
    --hop-Input-md-font-family: var(--hop-body-md-font-family);
    --hop-Input-md-font-size: var(--hop-body-md-font-size);
    --hop-Input-md-font-weight: var(--hop-body-md-font-weight);
    --hop-Input-md-line-height: var(--hop-body-md-line-height);
    --hop-Input-inline-size: 100%;
    --hop-Input-min-inline-size: 0;

    /** Internal */
    --input-placeholder-color: var(--hop-Input-placeholder-color);
    --font-family: var(--hop-Input-md-font-family);
    --font-size: var(--hop-Input-md-font-size);
    --font-weight: var(--hop-Input-md-font-weight);
    --line-height: var(--hop-Input-md-line-height);
    --block-size: var(--hop-Input-md-block-size);
    --color: var(--hop-Input-color);
    --background-color: var(--hop-Input-background);

    overflow: hidden;
    flex-grow: 1;
    flex-shrink: 1;

    box-sizing: border-box;
    inline-size: var(--hop-Input-inline-size);
    min-inline-size: var(--hop-Input-min-inline-size);
    block-size: var(--block-size);
    padding: 0;

    font-family: var(--font-family);
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
    color: var(--color);

    background-color: var(--background-color);
    border: 0;
    outline-style: none;
}

.hop-Input--sm {
    --font-family: var(--hop-Input-sm-font-family);
    --font-size: var(--hop-Input-sm-font-size);
    --font-weight: var(--hop-Input-sm-font-weight);
    --line-height: var(--hop-Input-sm-line-height);
    --block-size: var(--hop-Input-sm-block-size);
}

.hop-Input::placeholder {
    font-family: var(--hop-Input-placeholder-font-family);
    font-size: var(--hop-Input-placeholder-font-size);
    font-weight: var(--hop-Input-placeholder-font-weight);
    line-height: var(--hop-Input-placeholder-line-height);
    color: var(--input-placeholder-color);
}

.hop-Input[data-disabled] {
    --input-placeholder-color: var(--hop-Input-placeholder-color-disabled);
    --color: var(--hop-Input-color-disabled);
    --background-color: var(--hop-Input-background-disabled);
}
