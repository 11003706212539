.hop-Heading {
    /** Component Tokens */
    --hop-Heading-xs-font-size: var(--hop-heading-xs-font-size);
    --hop-Heading-xs-font-family: var(--hop-heading-xs-font-family);
    --hop-Heading-xs-font-weight: var(--hop-heading-xs-font-weight);
    --hop-Heading-xs-line-height: var(--hop-heading-xs-line-height);
    --hop-Heading-sm-font-size: var(--hop-heading-sm-font-size);
    --hop-Heading-sm-font-family: var(--hop-heading-sm-font-family);
    --hop-Heading-sm-font-weight: var(--hop-heading-sm-font-weight);
    --hop-Heading-sm-line-height: var(--hop-heading-sm-line-height);
    --hop-Heading-md-font-size: var(--hop-heading-md-font-size);
    --hop-Heading-md-font-family: var(--hop-heading-md-font-family);
    --hop-Heading-md-font-weight: var(--hop-heading-md-font-weight);
    --hop-Heading-md-line-height: var(--hop-heading-md-line-height);
    --hop-Heading-lg-font-size: var(--hop-heading-lg-font-size);
    --hop-Heading-lg-font-family: var(--hop-heading-lg-font-family);
    --hop-Heading-lg-font-weight: var(--hop-heading-lg-font-weight);
    --hop-Heading-lg-line-height: var(--hop-heading-lg-line-height);
    --hop-Heading-xl-font-size: var(--hop-heading-xl-font-size);
    --hop-Heading-xl-font-family: var(--hop-heading-xl-font-family);
    --hop-Heading-xl-font-weight: var(--hop-heading-xl-font-weight);
    --hop-Heading-xl-line-height: var(--hop-heading-xl-line-height);
    --hop-Heading-2xl-font-size: var(--hop-heading-2xl-font-size);
    --hop-Heading-2xl-font-family: var(--hop-heading-2xl-font-family);
    --hop-Heading-2xl-font-weight: var(--hop-heading-2xl-font-weight);
    --hop-Heading-2xl-line-height: var(--hop-heading-2xl-line-height);
    --hop-Heading-3xl-font-size: var(--hop-heading-3xl-font-size);
    --hop-Heading-3xl-font-family: var(--hop-heading-3xl-font-family);
    --hop-Heading-3xl-font-weight: var(--hop-heading-3xl-font-weight);
    --hop-Heading-3xl-line-height: var(--hop-heading-3xl-line-height);
    --hop-Heading-inherit-size-font-size: inherit;
    --hop-Heading-inherit-size-font-family: inherit;
    --hop-Heading-inherit-size-font-weight: inherit;
    --hop-Heading-inherit-size-line-height: inherit;
}

:where(.hop-Heading) {
    margin: 0;

    font-family: var(--font-family);
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
    color: var(--hop-neutral-text);
}

:where(.hop-Heading--xs) {
    --font-size: var(--hop-Heading-xs-font-size);
    --font-family: var(--hop-Heading-xs-font-family);
    --font-weight: var(--hop-Heading-xs-font-weight);
    --line-height: var(--hop-Heading-xs-line-height);
}

:where(.hop-Heading--sm) {
    --font-size: var(--hop-Heading-sm-font-size);
    --font-family: var(--hop-Heading-sm-font-family);
    --font-weight: var(--hop-Heading-sm-font-weight);
    --line-height: var(--hop-Heading-sm-line-height);
}

:where(.hop-Heading--md) {
    --font-size: var(--hop-Heading-md-font-size);
    --font-family: var(--hop-Heading-md-font-family);
    --font-weight: var(--hop-Heading-md-font-weight);
    --line-height: var(--hop-Heading-md-line-height);
}

:where(.hop-Heading--lg) {
    --font-size: var(--hop-Heading-lg-font-size);
    --font-family: var(--hop-Heading-lg-font-family);
    --font-weight: var(--hop-Heading-lg-font-weight);
    --line-height: var(--hop-Heading-lg-line-height);
}

:where(.hop-Heading--xl) {
    --font-size: var(--hop-Heading-xl-font-size);
    --font-family: var(--hop-Heading-xl-font-family);
    --font-weight: var(--hop-Heading-xl-font-weight);
    --line-height: var(--hop-Heading-xl-line-height);
}

:where(.hop-Heading--2xl) {
    --font-size: var(--hop-Heading-2xl-font-size);
    --font-family: var(--hop-Heading-2xl-font-family);
    --font-weight: var(--hop-Heading-2xl-font-weight);
    --line-height: var(--hop-Heading-2xl-line-height);
}

:where(.hop-Heading--3xl) {
    --font-size: var(--hop-Heading-3xl-font-size);
    --font-family: var(--hop-Heading-3xl-font-family);
    --font-weight: var(--hop-Heading-3xl-font-weight);
    --line-height: var(--hop-Heading-3xl-line-height);
}
